.product-banner {
  background: url('assets/backgrounds/products_banner.webp'),
    linear-gradient(180deg, #090a11 15%, #010613 70%);
  background-size: 100% auto;
  background-color: black;
  background-position: center bottom;
  background-repeat: no-repeat;
}

@media (max-width: 1440px) {
  .product-banner {
    background-position: center bottom 20%;
  }
}

@media (max-width: 1280px) {
  .product-banner {
    background-position: center bottom;
    background-size: 100% 100%;
  }
}

@media (max-width: 992px) {
  .product-banner {
    background: url('assets/backgrounds/products_banner.webp') no-repeat bottom,
      linear-gradient(180deg, #090a11 15%, #0d121d 100%);
    background-size: 100% auto;
  }
}

@media (max-width: 668px) {
  .product-banner {
    background: url('assets/backgrounds/products_banner_mobile.png') no-repeat;
    background-size: 100%;
    background-position: bottom;
  }
}

@media (max-width: 636px) {
  .product-banner {
    background: url('assets/backgrounds/products_banner_mobile.png') no-repeat
      center;
    background-size: 100%;
    background-position: bottom;
  }
}

.fade-show-enter {
  opacity: 0;
}

.fade-show-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.fade-show-exit {
  opacity: 1;
}

.fade-show-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
