@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth !important;
}

button,
span {
  letter-spacing: 0.8px;
}

h1,
h2 {
  letter-spacing: 0.6px;
}

h3 {
  letter-spacing: 1.3px;
}
h4,
h5 {
  letter-spacing: 0.6px;
}
h6 {
  letter-spacing: 0.4px;
}

dl,
dd,
li {
  letter-spacing: 0.8px;
}
a {
  letter-spacing: 0.5px;
}
span {
  letter-spacing: 1.2px;
}

/* Sequel Sans */

@font-face {
  font-family: 'bold';

  src: url('assets/fonts/bold.otf');
}
@font-face {
  font-family: 'medium';

  src: url('assets/fonts/medium.otf');
}
@font-face {
  font-family: 'regular';

  src: url('assets/fonts/regular.otf');
}
@font-face {
  font-family: 'light';

  src: url('assets/fonts/light.otf');
}
@font-face {
  font-family: 'semibold';

  src: url('assets/fonts/semibold.otf');
}

@layer components {
  /* .d {
    @apply border-2 border-danger border-solid;
  } */
  .w-container {
    @apply max-w-screen-3xl mx-auto 2xl:w-[80%] md:w-[90%] px-3;
  }
  .blade-top-padding {
    @apply pt-20 md:pt-24  lg:pt-28  xl:pt-36 2xl:pt-44;
  }
  .blade-top-padding-sm {
    @apply pt-10 md:pt-16  lg:pt-20  xl:pt-24;
  }
  .blade-bottom-padding {
    @apply pb-20 md:pb-24  lg:pb-28  xl:pb-36 2xl:pb-44;
  }
  .blade-bottom-padding-sm {
    @apply pb-10 md:pb-16  lg:pb-20  xl:pb-24;
  }
  .banner {
    @apply min-h-[700px] md:min-h-[500px] h-fit;
  }

  .bg-blue-drop-shadow {
    background: rgb(193, 206, 252);
    background: radial-gradient(
      at bottom,
      rgba(193, 206, 252, 0.6) 5%,
      rgba(255, 255, 255, 1) 90%
    );
    background-size: cover;
  }

  .bg-blue-gradient {
    background-image: linear-gradient(45deg, #0b0b0b, #000000 100%);
  }
  .bg-blue-light-gradient::after {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    transition: background-size 0.3s ease-in-out;
    background-size: 400% 400%;
    background-image: linear-gradient(135deg, #fff 0%, #3661ff 171%);
  }
  .bg-blue-light-gradient:hover::after {
    background-size: 100% 100%;
  }
  .text-gold-gradient {
    padding-bottom: 10px;
    background-image: linear-gradient(
        119deg,
        #ffedbe -2%,
        #f5c750 51%,
        #ffedbe 111%
      ),
      linear-gradient(to bottom, #fff, #fff);
  }
  .text-blue-gradient {
    background-image: linear-gradient(110deg, #3661ff 5%, #839dfe 93%);
  }
  .text-logo-gradient {
    background-image: linear-gradient(110deg, #141f44 5%, #1382b8 93%);
  }
  .bg-table-blue {
    background: linear-gradient(160.28deg, #2a63e0 0%, #4a3aff 104.01%);
  }
  .bg-software-services {
    background-image: url('./assets/home/world-map.png');
    background-position: right -2% top -60px;
    /* border: 2px solid red; */
    background-repeat: no-repeat;
    background-size: min(100%, 40%) auto;
  }
  @media (max-width: 1536px) {
    .bg-software-services {
      background-position: right top 0px;
    }
  }
  @media (max-width: 1080px) {
    .bg-software-services {
      background-image: none;
    }
  }

  .bg-dark-products-blade {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 9, 41, 0) 3%,
        #000929 100%
      ),
      linear-gradient(to bottom, #000 0%, #000 35%, #0e0e0e 100%);
    background-size: auto, auto;
    background-origin: left, left;
    background-repeat: no-repeat, no-repeat;
  }
  .bg-dark-product {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 9, 41, 0) 3%,
        #000929 100%
      ),
      linear-gradient(to bottom, #000 0%, #000 35%, #0e0e0e 100%);
    background-size: auto, auto;
    background-origin: right top, left, left;
    background-repeat: no-repeat, no-repeat;
  }
  .bg-blur {
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 1);
  }
  .bg-blue-gradient {
    background: linear-gradient(180deg, #000000 30%, #0d121d 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: top;
  }

  .home-banner-gradient {
    background: linear-gradient(180deg, #090a11 15%, #010511 70%);
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: top;
  }
}

@layer base {
  h1 {
    @apply text-[32px] md:text-4xl lg:text-5xl 2xl:text-6xl  leading-tight;
  }
  h2 {
    @apply text-2xl md:text-3xl  lg:text-4xl 2xl:text-5xl  leading-tight md:leading-tight lg:leading-tight  2xl:leading-tight;
  }
  h3 {
    @apply text-xl lg:text-3xl 2xl:text-4xl  leading-tight 2xl:leading-tight;
  }
  h4 {
    @apply text-xl xl:text-3xl leading-tight;
  }
  h5 {
    @apply text-lg  sm:text-xl lg:text-2xl leading-normal md:leading-snug lg:leading-snug;
  }
  h6 {
    @apply text-sm sm:text-base  md:text-lg 2xl:text-xl text-black   leading-normal md:leading-normal xl:leading-normal;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #2a63e0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2a63e0;
}

::-webkit-scrollbar-track {
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8;
}

.swiper-pagination-bullets {
  bottom: 5px !important;
}
.swiper-pagination-bullet {
  background-color: #7e7e7e !important;
}
.swiper-pagination-bullet-active {
  background-color: #2a63e0 !important;
}

.swiper-slide {
  height: auto !important;
}

table {
  border-collapse: collapse;
  margin: 0 auto;
  table-layout: auto;
}
table td {
  padding: 1rem;
  border: 1px dashed rgba(255, 255, 255, 0.2);
}
table tr:first-child td {
  border-left: 0;
}
table tr:last-child td {
  border-bottom: 0;
}

table tr td:first-child {
  border-left: 0;
  border-bottom: 0;
}
table tr td:last-child {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
table tr:nth-child(1) td {
  border-top: 1px solid transparent;
}
table tr:nth-child(1) {
  border-top: 1px solid transparent;
}

@media screen and (max-width: 768px) {
  table td {
    border-right: none;
  }
}

.disabled-link {
  @apply pointer-events-none touch-none cursor-not-allowed;
}

.swiper-btn-next,
.swiper-btn-prev {
  position: static !important;
  height: 50px;
  width: 50px;
  fill: white;
  border: 2px solid white;
  border-radius: 50%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.gradient-dark {
  background: linear-gradient(
    180deg,
    #000000 -7.96%,
    #000000 29.83%,
    #000929 100%
  );
}

.gray-drop-shadow {
  box-shadow: 0 0 5px rgba(193, 206, 252, 0.6);
}
.gray-drop-shadow-inset {
  box-shadow: 0 0 4px 1px rgba(193, 206, 252, 0.6);
}
.gray-drop-shadow-m {
  box-shadow: 0 0 5px rgba(193, 206, 252, 0.6);
  margin-top: 10px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 380px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content div:first-child {
  opacity: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content div:first-child {
  animation: fade-in 0.5s ease;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
