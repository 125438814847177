@keyframes showAnime {
  from {
    height: 110%;
  }
  to {
    height: 0%;
  }
}

.mockup-illustration {
  padding-left: 10px;
}
.appear-animation {
  animation: showAnime 3s ease-in-out;
}
.product-card {
  background: linear-gradient(
      182deg,
      rgba(255, 255, 255, 0.5) 58%,
      rgba(149, 176, 245, 0.5) 100%
    ),
    #fff;
}

.product-flex-card {
  background: url('assets/backgrounds/controller_bg.png') no-repeat right top;
  background-size: 32%;
}
.animation-gradient {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    #000000a3 5%,
    #000 10%
  );
  /* background: #000; */
}
.animation-gradient-blue {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    #090d15 5%,
    #090d15 10%
  );
}

.settings-icon {
  background: radial-gradient(black 50%, transparent 50%);
}

@media (max-width: 1480px) {
  .product-flex-card {
    background-size: 28%;
  }
}

@media (max-width: 1280px) {
  .product-flex-card {
    z-index: 1;
    background-size: 30%;
  }
}

@media (max-width: 1024px) {
  .product-flex-card {
    background: none;
  }
}
