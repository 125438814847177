.banner-bg {
  background-color: black;
  background: url('assets/backgrounds/software_banner-2.png') no-repeat right
    bottom;
  background-size: 60%;
}

@media (max-width: 1024px) {
  .banner-bg {
    background-size: 50%;
  }
}

@media (max-width: 768px) {
  .banner-bg {
    background-position: bottom right;
    background-size: 70%;
  }
}

@media (max-width: 636px) {
  .banner-bg {
    background-size: 100%;
  }
}
